<script lang="ts" setup>
import { Vue3Lottie } from 'vue3-lottie'
import CheckAnimation from "@/assets/check.json";


const achievement = ref();
const aController = ref(null);


const restart = () => {
		const controller = aController.value as any
		controller.stop()
		controller.play()  
}

</script>
<template>
    <Intersection @enter="restart" :threshold="0.3">
        <div ref="achievement" class="flex flex-col md:flex-row max-w-screen-lg pt-20 pb-10 mx-auto gap-6 lg:gap-10 items-center px-4">
        
            <figure class="w-full flex-1 bg-[#7FC8BB] rounded-2xl drop-shadow-xl relative">
                <img
                    alt="Nicotine"
                    src="@/assets/icon_nicotine.svg"
                    class="w-48 h-48 mx-auto my-12"
                    width="240" height="240"
                    />

                <div class="absolute left-1/2 top-1/2">
									<ClientOnly> 
										<div  class="relative translate-x-2 -translate-y-32 w-20 h-20">
											<Vue3Lottie
													ref="aController"
													alt="Check icon animation"
													:loop="false"
													:auto-play="false"
													:animationData="CheckAnimation" 
													/>   
										</div>
									</ClientOnly>
                </div>
                
                <p class="text-white text-3xl leading-6 font-bold absolute bottom-7 left-7" v-html="$t('home.features.achievements.nicotine_degraded')"></p>
            </figure>
            
            <div class="pt-6 md:pt-0 px-6 md:px-0 flex-1 w-full">
                <p class="text-primary-500">{{$t('home.features.achievements.title')}}</p>
                <h3 class="text-3xl pr-4 font-bold font-owners text-dark-900">{{$t('home.features.achievements.headline')}}</h3>
                <p class="pt-4">{{$t('home.features.achievements.body')}}</p>
            </div>  

            
            
        </div>
    </Intersection>
</template>